import React from "react"
import { RiPlayFill } from "react-icons/ri";
import { Link } from 'gatsby';
import { Button } from '@material-ui/core';

import SEO from "../components/seo/seo"
import Header from '../components/header/header';
// @ts-ignore
import arcadeImage from '../images/2021_01_Arcade_Box.svg';
import FourOhFourStyle from '../modules/404/404-styles';

const NotFoundPage = (props) => {
  const href = props.location.pathname;
  return (
    <>
      <SEO title="404: Page Not Found" />
      <Header
        title="404" 
      />
      <FourOhFourStyle className="container-fluid pt-0 pt-md-5 text-center text-md-left">
        <div className="row align-items-center">
          <div className="offset-0 offset-md-1 col-12 col-md-auto order-12 order-md-1">
            <div className="my-4">
              <img src={arcadeImage} alt="arcade box graphic"/>
            </div>
          </div>
          <div className="col-12 col-md order-1 order-md-12 mt-5 mt-md-0">
            <h1>404: Page Not Found</h1>
            <p>The <strong>horror</strong>, the <strong><i>madness</i></strong>, the <i>sadness</i>...</p>
            <p>Press Start to go back home.</p>
            <Link to="/">
              <Button
                variant="contained"
                startIcon={<RiPlayFill size="16" />}
                style={{ fontFamily: 'monospace', fontWeight: 500, textTransform: 'uppercase' }}
              >Start</Button>
            </Link>
          </div>
        </div>
      </FourOhFourStyle>
    </>
  );
};

export default NotFoundPage
