import styled from 'styled-components'

const FourOhFourStyle = styled.div`
  img {
    min-height: 25rem;
    height: 50%;
    max-height: 30rem;
    margin: 3rem auto;
  }
`;

export default FourOhFourStyle